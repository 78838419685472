import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
