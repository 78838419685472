import { Button } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import React, { FC } from 'react';
import useStyles from './DeleteModal.styles';
import { IDeleteModalProps } from './interface';

const DeleteModal: FC<IDeleteModalProps> = ({ open, handleClose, user, onDelete }) => {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">{`Are you sure you want to delete user ${user.name} - Id:${user.id} ?`}</h2>
            <p id="transition-modal-description">This user will be deleted immediately. You can´t undo this action.</p>
            <Button variant="contained" className={classes.button} onClick={onDelete}>
              Delete
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default DeleteModal;
