import { TextField } from '@material-ui/core';
import { FormPaStateContext } from 'components/FormPA/formPaProvider';
import React, { FC, useCallback, useContext } from 'react';
import ReviewerGrades from './components/ReviewerGrades';
import useStyles from './Details.styles';
import { IDetailsProps } from './interfaces';

const FormPADetails: FC<IDetailsProps> = () => {
  // eslint-disable-next-line operator-linebreak
  const { components, handleEditDetailInput, handleDetailInput, values, reviewer, editPage, viewPage, disableDetails } =
    useContext(FormPaStateContext);
  const classes = useStyles();

  const listOfDocumentComponentComponentIds = (values.documentComponents || []).map(
    (dc: { component: { id: any } }) => dc.component.id
  );

  const getTextField = useCallback(
    component => {
      const index = listOfDocumentComponentComponentIds.indexOf(component.id);

      if (index !== -1) {
        return (
          <>
            <TextField
              // eslint-disable-next-line react/jsx-no-bind
              onChange={e => handleEditDetailInput(values?.documentComponents[index].id, e, 'practitionerComment')}
              id="outlined-multiline-static"
              className={classes.input}
              label={component.name}
              variant="outlined"
              multiline
              rows={10}
              name={component.name}
              defaultValue={values?.documentComponents[index].practitionerComment}
              disabled={viewPage || disableDetails || reviewer}
            />

            <>
              <ReviewerGrades index={index} />
              <TextField
                // eslint-disable-next-line react/jsx-no-bind
                onChange={e => handleEditDetailInput(values?.documentComponents[index].id, e, 'reviewerComment')}
                id="outlined-multiline-static"
                className={classes.input}
                label={`Reviewer ${component.name} comment`}
                variant="outlined"
                multiline
                rows={10}
                name={component.name}
                defaultValue={values?.documentComponents[index].reviewerComment}
                disabled={viewPage || disableDetails || editPage}
              />
            </>
          </>
        );
      }

      return (
        <TextField
          // eslint-disable-next-line react/jsx-no-bind
          onChange={e => handleDetailInput(component.id, e)}
          id="outlined-multiline-static"
          className={classes.input}
          label={component.name}
          variant="outlined"
          multiline
          rows={10}
          name={component.name}
          disabled={viewPage || disableDetails}
        />
      );
    },
    [
      classes.input,
      disableDetails,
      editPage,
      handleDetailInput,
      handleEditDetailInput,
      listOfDocumentComponentComponentIds,
      reviewer,
      values?.documentComponents,
      viewPage,
    ]
  );

  return (
    <div>
      {values && (
        <div className={classes.container}>
          <h1>Project Details</h1>

          {components.map(component => (
            <div className={classes.column} key={component.id}>
              <h3>{component.name}</h3>
              <div>{component.desc}</div>
              {getTextField(component)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FormPADetails;
