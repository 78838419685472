import { ApolloProvider } from '@apollo/client';
import { client } from 'apollo/apollo';
import UserStateProvider from 'helper/userProvider';
import React from 'react';
import Routes from 'Routes';
import './App.css';

function App() {
  return (
    <UserStateProvider>
      <ApolloProvider client={client}>
        <Routes />
      </ApolloProvider>
    </UserStateProvider>
  );
}

export default App;
