import { useQuery } from '@apollo/client';
import { GET_ADMIN_DOCUMENT_LIST } from 'graphql/queries';
import { useCallback, useEffect, useState } from 'react';
import { IListProps } from './interface';

export const AdminDocumentListPageViewModel = () => {
  // const [filter, setFilter] = useState(false);
  const filter = false;
  const [documents, setDocuments] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState<number>(0);
  const [limit, setLimit] = useState(5);
  const [value, setValue] = useState(1);
  const { data, fetchMore } = useQuery(GET_ADMIN_DOCUMENT_LIST, {
    variables: {
      listPaDocumentsForAdminInput: {
        pagination: {
          limit,
          page: page + 1,
        },
      },
    },
    fetchPolicy: 'network-only',
  });

  // const handleFilterChange = () => {
  //   setFilter(!filter);
  // };

  useEffect(() => {
    if (data) {
      setDocuments(
        data.listPaDocumentsForAdmin.paDocuments.map((list: IListProps) => ({
          id: list.id,
          projectName: list.projectName,
          createdAt: list.createdAt,
          monthFromTo: list.monthFromTo,
          hoursSpent: list.hoursSpent,
          status: list.status.name,
          practitioner: `${list.practitioner.firstName} ${list.practitioner.lastName}`,
          reviewer: `${list.reviewer.firstName} ${list.reviewer.lastName}`,
        }))
      );
      setCount(data.listPaDocumentsForAdmin.count);
    }
  }, [data]);

  const fetchData = useCallback(() => {
    fetchMore({
      variables: {
        listPaDocumentsForAdminInput: {
          pagination: {
            limit,
            page: page + 1,
          },
        },
      },
    });
  }, [fetchMore, limit, page]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    {
      title: 'ID',
      field: 'id',
      sorting: false,
    },
    {
      title: 'Project name',
      field: 'projectName',
      sorting: false,
    },
    {
      title: 'Created at',
      field: 'createdAt',
      sorting: false,
    },
    {
      title: 'Month from to',
      field: 'monthFromTo',
      sorting: false,
    },
    {
      title: 'Hours spent',
      field: 'hoursSpent',
      sorting: false,
    },
    {
      title: 'Practitioner',
      field: 'practitioner',
      sorting: false,
    },
    {
      title: 'Reviewer',
      field: 'reviewer',
      sorting: false,
    },
    {
      title: 'Status',
      field: 'status',
      sorting: false,
    },
    {
      title: 'Action',
      field: 'action',
      sorting: false,
      align: 'center',
    },
  ];
  const title = 'List of Documents';

  return {
    value,
    documents,
    filter,
    data,
    columns,
    title,
    // handleFilterChange,
    page,
    count,
    limit,
    setLimit,
    setPage,
    setValue,
  };
};
