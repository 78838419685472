export const LOGIN_PAGE_PATH = '/login';
export const ADMIN_USERS_PAGE_PATH = '/admin/user/list';
export const ADMIN_DOCUMENTS_PAGE_PATH = '/admin/document/list';
export const PRACTITIONER_PAGE_PATH = '/practitioner';
export const PRACTITIONER_CREATE_DOCUMENT_PAGE_PATH = '/practitioner/create/document';
export const PRACTITIONER_UPDATE_DOCUMENT_PAGE_PATH = '/practitioner/pa/update/:id';
export const PRACTITIONER_VIEW_DOCUMENT_PAGE_PATH = '/practitioner/pa/view/:id';
export const REVIEWER_PAGE_PATH = '/reviewer';
export const REVIEWER_REVIEW_PATH = '/reviewer/pa/update/:id';
export const REVIEWER_VIEW_PATH = '/reviewer/pa/view/:id';
export const ADMIN_CREATE_USER_PAGE_PATH = '/admin/create/user';
export const USER_ADMIN_ROLE = 'Admin';
export const USER_REVIEWER_ROLE = 'Reviewer';
export const USER_PRACTITIONER_ROLE = 'Practitioner';
