import { Typography } from '@material-ui/core';
import { FormPaStateContext } from 'components/FormPA/formPaProvider';
import { useContext } from 'react';
import Modal from './components/Modal';
import useStyles from './modals.styles';

// import { FormPAViewModel } from './FormPA.ViewModel';

const Modals = () => {
  const {
    handleCloseDraft,
    handleOpenDraft,
    openDraft,
    openSubmit,
    handleCloseSubmit,
    handleOpenSubmit,
    onSubmit,
    onSubmitReview,
    viewPage,
    reviewer,
    disableDetails,
  } = useContext(FormPaStateContext);
  const classes = useStyles();
  return (
    <div>
      {viewPage || disableDetails || reviewer ? null : (
        <Typography align="right" className={classes.modal}>
          <Modal
            submit={onSubmit}
            title="Draft"
            action="Draft"
            desc="Your document is saved as draft!"
            handleClose={handleCloseDraft}
            handleOpen={handleOpenDraft}
            value="1"
            open={openDraft}
          />
          <Modal
            submit={onSubmit}
            title="Submit"
            action="Submitted"
            desc="Your document is submitted!"
            value="2"
            handleClose={handleCloseSubmit}
            handleOpen={handleOpenSubmit}
            open={openSubmit}
          />
        </Typography>
      )}
      {reviewer ? (
        <Typography align="right" className={classes.modal}>
          <Modal
            submit={onSubmitReview}
            title="Submit"
            action="Reviewed"
            value="2"
            desc="Your document is submitted!"
            handleClose={handleCloseSubmit}
            handleOpen={handleOpenSubmit}
            open={openSubmit}
          />
        </Typography>
      ) : null}
    </div>
  );
};

export default Modals;
