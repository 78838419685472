import DashboardLayout from 'components/DashboardLayout';
import FormPA from 'components/FormPA';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import FormPaStateProvider from 'components/FormPA/formPaProvider';
import { PractitionerUpdatePaViewModel } from './PractitionerUpdatePa.ViewModel';

const PractitionerUpdatePA: FC<RouteComponentProps> = () => {
  const { id } = useParams<{ id: string }>();
  const vm = PractitionerUpdatePaViewModel(id);

  if (vm.loading) return <div>Loading ...</div>;
  if (!vm.formDetails) return <div>Form details not set ...</div>;

  return (
    <div>
      <FormPaStateProvider formDetails={vm.formDetails} editPage>
        <DashboardLayout>
          <FormPA />
        </DashboardLayout>
      </FormPaStateProvider>
    </div>
  );
};

export default PractitionerUpdatePA;
