import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
  },

  input: {
    width: '100%',
    marginTop: '20px',
    marginBottom: '20px',
  },
}));

export default useStyles;
