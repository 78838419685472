/* eslint-disable react/jsx-no-bind */

import { Button, TextField } from '@material-ui/core';
import useStyles from 'pages/LoginPage/loginPage.styles';
import React from 'react';
import { LoginFormViewModel } from './LoginForm.ViewModel';

const LoginForm = () => {
  const classes = useStyles();
  const vm = LoginFormViewModel();

  return (
    <form className={classes.form} noValidate>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        onChange={vm.handleInput}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        onChange={vm.handleInput}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={vm.onSubmit}>
        Sign In
      </Button>
    </form>
  );
};

export default LoginForm;
