import { useQuery } from '@apollo/client';
import { GET_PA_DOCUMENT_FOR_REVIEWER } from 'graphql/queries';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

export const ReviewerViewPaViewModel = () => {
  const [formDetails, setFormDetails] = useState<any>();
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useQuery(GET_PA_DOCUMENT_FOR_REVIEWER, {
    variables: {
      getPaDocumentInput: {
        id,
      },
    },
  });

  useEffect(() => {
    if (data) {
      setFormDetails(data?.getPaDocument);
    }
  }, [data]);

  // eslint-disable-next-line no-console
  console.log(formDetails);

  return {
    formDetails,
    data,
    loading,
  };
};
