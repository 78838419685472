import { useMutation } from '@apollo/client';
import { GET_USER_DATA } from 'graphql/mutations';
import { UserStateContext } from 'helper/userProvider';
import { ChangeEvent, MouseEvent, useContext, useState } from 'react';

export const LoginFormViewModel = () => {
  const { appSetLogin } = useContext(UserStateContext);
  const [login] = useMutation(GET_USER_DATA);
  const [values, setValues] = useState({
    email: '',
    password: '',
  });

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
    try {
      e.preventDefault();
      const response = await login({ variables: { loginInput: values } });

      const token: string = response.data?.login?.token;

      appSetLogin(token);

      // eslint-disable-next-line no-console
      console.log(token);
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert(err);
    }
  };
  return {
    values,
    setValues,
    handleInput,
    onSubmit,
  };
};
