import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    justifyContent: 'start',
  },

  input: {
    width: '35%',
    marginTop: '20px',
    marginBottom: '20px',
    marginRight: '40px',
  },
}));

export default useStyles;
