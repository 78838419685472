import React, { FC } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { RouteComponentProps } from 'react-router';
import DashboardLayout from 'components/DashboardLayout';
import CreateUserForm from './components/CreateUserForm';
import useStyles from './AdminCreateUserPage.styles';

const AdminCreateUserPage: FC<RouteComponentProps> = () => {
  const classes = useStyles();

  return (
    <DashboardLayout>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <PersonAddIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Create New User
          </Typography>
          <CreateUserForm />
        </div>
      </Container>
    </DashboardLayout>
  );
};

export default AdminCreateUserPage;
