import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import useStyles from 'components/DashboardLayout/dashboardLayout.styles';
import { FC } from 'react';
import { IHeaderProps } from './interfaces';

const Header: FC<IHeaderProps> = ({ open, handleDrawerOpen }) => {
  const classes = useStyles();

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, open && classes.hide)}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h5" noWrap className={classes.title}>
          Project Assessment
        </Typography>
        <Typography>
          <img src="https://iolap-com-strapi-cms-upload.s3.amazonaws.com/logo30_white_8a6bdd13f1.png" alt="" />
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
