import { gql } from '@apollo/client';

export const GET_USER_DATA = gql`
  mutation LoginMutation($loginInput: UserInfo!) {
    login(input: $loginInput) {
      token
    }
  }
`;

export const CREATE_PA_DOCUMENT = gql`
  mutation Mutation($createPaDocumentInput: PaDocumentInput!) {
    createPaDocument(input: $createPaDocumentInput) {
      projectName
      role
      year
      monthFromTo
      hoursSpent
      createdAt
      documentComponents {
        id
        practitionerComment
        component {
          id
          name
        }
      }
      practitioner {
        id
        firstName
      }
      reviewer {
        id
        firstName
      }
      status {
        id
        name
      }
    }
  }
`;

export const EDIT_PA_DOCUMENT = gql`
  mutation EditPaDocumentMutation($editPaDocumentInput: EditPaDocumentInput!) {
    editPaDocument(input: $editPaDocumentInput) {
      projectName
    }
  }
`;

export const REVIEW_PA_DOCUMENT = gql`
  mutation UpdatePaDocumentComponentMutation($updatePaDocumentComponentInput: UpdatePaDocumentInput!) {
    updatePaDocumentComponent(input: $updatePaDocumentComponentInput) {
      id
      projectName
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUserMutation($deleteUserInput: deleteUserInput!) {
    deleteUser(input: $deleteUserInput)
  }
`;

export const CREATE_USER = gql`
  mutation CreateNewUserMutation($createNewUserInput: CreateNewUserInput!) {
    createNewUser(input: $createNewUserInput) {
      id
      firstName
      lastName
      email
      createdAt
      updatedAt
      reviewer {
        firstName
        email
      }
      serviceLine {
        name
      }
      roles {
        name
      }
    }
  }
`;
