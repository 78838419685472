import { Box } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import Copyright from './components/CopyRight';
import LoginForm from './components/LoginForm';
import useStyles from './loginPage.styles';

const SignIn: FC<RouteComponentProps> = () => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          iOLAP Project Assessment
        </Typography>
        <LoginForm />
      </div>
      <Box mt={4}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default SignIn;
