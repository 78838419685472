import Paper from '@material-ui/core/Paper';
import React, { FC, useContext } from 'react';
import Details from './components/Details';
import Info from './components/Info';
import Modals from './components/Modals';

import useStyles from './formPA.styles';
// import { FormPAViewModel } from './FormPA.ViewModel';
import { FormPaStateContext } from './formPaProvider';
import { IFormPAProps } from './interfaces';

const FormPA: FC<IFormPAProps> = () => {
  // eslint-disable-next-line operator-linebreak
  const { components } = useContext(FormPaStateContext);
  const classes = useStyles();

  return (
    <div>
      {components && (
        <Paper className={classes.paper}>
          <form noValidate>
            <Info />
            <Details />
            <Modals />
          </form>
        </Paper>
      )}
    </div>
  );
};

export default FormPA;
