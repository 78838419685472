import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  paper: {
    margin: '50px 100px',
    padding: '60px',
    height: '100%',
  },

  modal: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
