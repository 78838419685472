import DashboardLayout from 'components/DashboardLayout';
import FormPA from 'components/FormPA';
import FormPaStateProvider from 'components/FormPA/formPaProvider';
import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { ReviewerReviewPaViewModel } from './ReviewerReviewPa.ViewModel';

const ReviewerReviewPa: FC<RouteComponentProps> = () => {
  const vm = ReviewerReviewPaViewModel();

  if (vm.loading) return <div>Loading ...</div>;
  if (!vm.formDetails) return <div>Form details not set ...</div>;

  return (
    <div>
      <FormPaStateProvider formDetails={vm.formDetails} reviewer>
        <DashboardLayout>
          <FormPA />
        </DashboardLayout>
      </FormPaStateProvider>
    </div>
  );
};

export default ReviewerReviewPa;
