import DashboardLayout from 'components/DashboardLayout';
import Table from 'components/Table';
import { PRACTITIONER_CREATE_DOCUMENT_PAGE_PATH } from 'helper/constants';
import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { PractitionerPageViewModel } from './PractitionerPage.ViewModel';

const PractitionerPage: FC<RouteComponentProps> = () => {
  const vm = PractitionerPageViewModel();

  return (
    <div>
      <DashboardLayout>
        <Table
          title={vm.title}
          columns={vm.columns}
          data={vm.documents}
          filter={vm.filter}
          handleFilterChange={vm.handleFilterChange}
          setPage={vm.setPage}
          setLimit={vm.setLimit}
          pageNum={vm.page}
          count={vm.count}
          setStatus={vm.setStatus}
          setSort={vm.setSort}
          createButtonLabel="Create PA document"
          createButtonRoutePath={PRACTITIONER_CREATE_DOCUMENT_PAGE_PATH}
        />
      </DashboardLayout>
    </div>
  );
};

export default PractitionerPage;
