import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { FormPaStateContext } from 'components/FormPA/formPaProvider';
import React, { FC, useContext } from 'react';
import useStyles from './Info.styles';
import { IInfoProps } from './interfaces';

const FormPAInfo: FC<IInfoProps> = () => {
  // eslint-disable-next-line operator-linebreak
  const { handleInput, values, reviewerList, handleReviewerChange, reviewer, viewPage, disableInfo } =
    useContext(FormPaStateContext);
  const classes = useStyles();

  // eslint-disable-next-line no-console
  console.log('val', values);

  return (
    <div>
      {values && (
        <div className={classes.container}>
          <h1>Project Info</h1>
          <div className={classes.row}>
            <TextField
              key={1}
              className={classes.input}
              id="outlined-basic"
              label="Project Name"
              variant="outlined"
              onChange={handleInput}
              name="projectName"
              defaultValue={values.projectName}
              disabled={viewPage || disableInfo || reviewer}
            />
            <TextField
              key={2}
              className={classes.input}
              id="outlined-basic"
              label="Role on the project"
              variant="outlined"
              onChange={handleInput}
              name="role"
              defaultValue={values.role}
              disabled={viewPage || disableInfo || reviewer}
            />
          </div>
          <div className={classes.row}>
            <TextField
              key={3}
              className={classes.input}
              id="outlined-basic"
              label="Year"
              variant="outlined"
              onChange={handleInput}
              name="year"
              defaultValue={values.year}
              disabled={viewPage || disableInfo || reviewer}
            />
            <TextField
              key={4}
              className={classes.input}
              id="outlined-basic"
              label="Assessment Month Range"
              variant="outlined"
              onChange={handleInput}
              name="monthFromTo"
              defaultValue={values.monthFromTo}
              disabled={viewPage || disableInfo || reviewer}
            />
          </div>
          <div className={classes.row}>
            <TextField
              key={5}
              className={classes.input}
              id="outlined-basic"
              label="Hours spent on the project in that period"
              variant="outlined"
              onChange={handleInput}
              name="hoursSpent"
              defaultValue={values.hoursSpent}
              disabled={viewPage || disableInfo || reviewer}
            />
            <FormControl variant="outlined" className={classes.input} disabled={viewPage || disableInfo || reviewer}>
              <InputLabel id="demo-simple-select-outlined-label">Select Reviewer</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={values.reviewerId}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={handleReviewerChange}
                label="Selct Reviewer">
                {(reviewerList || []).map(
                  (user: { id: string | number | undefined; firstName: any; lastName: any }) => (
                    <MenuItem key={user.id} value={user.id}>{`${user.firstName} ${user.lastName}`}</MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormPAInfo;
