/* eslint-disable max-len */
import { Box, Button, Checkbox } from '@material-ui/core';
import MaterialTable from 'material-table';
import * as React from 'react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ITableProps } from './interfaces';
import useStyles from './table.styles';

const DataTable: FC<ITableProps> = ({
  filter,
  handleFilterChange,
  title,
  data,
  columns,
  setPage,
  setLimit,
  pageNum,
  count,
  setStatus,
  setSort,
  disableButton,
  createButtonLabel,
  createButtonRoutePath,
  hideFilterCheckbox,
  selectTab,
}) => {
  const classes = useStyles();

  return (
    <div style={{ height: 400, width: '100%' }}>
      <div className={classes.optionContainer}>
        {selectTab}
        {hideFilterCheckbox ? null : (
          <Box>
            Filter option:
            <Checkbox
              checked={filter}
              onChange={handleFilterChange}
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Box>
        )}

        {disableButton ? null : (
          <Button to={createButtonRoutePath} component={Link} variant="contained" className={classes.button}>
            {createButtonLabel}
          </Button>
        )}
      </div>

      <MaterialTable
        title={title}
        data={data}
        columns={columns}
        options={{
          search: false,
          filtering: filter,
          headerStyle: {
            fontSize: '1rem',
            fontWeight: 'bold',
          },
          sorting: true,
        }}
        // eslint-disable-next-line react/jsx-no-bind
        onChangePage={(page, pageSize) => {
          setPage(page);
          setLimit(pageSize);
        }}
        // eslint-disable-next-line react/jsx-no-bind
        onFilterChange={e => setStatus(e[0].value[0])}
        // eslint-disable-next-line react/jsx-no-bind
        onOrderChange={(_, orderDirection) => setSort(orderDirection)}
        totalCount={count}
        page={pageNum}
      />
    </div>
  );
};
export default DataTable;
