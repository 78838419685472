import { useQuery } from '@apollo/client';
import { GET_PA_DOCUMENT } from 'graphql/queries';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

export const PractitionerViewPaViewModel = () => {
  const [formData, setFormData] = useState<any>();
  const { id } = useParams<{ id: string }>();

  const { data, loading } = useQuery(GET_PA_DOCUMENT, {
    variables: {
      getPaDocumentInput: {
        id,
      },
    },
  });

  useEffect(() => {
    if (data) {
      setFormData(data?.getPaDocument);
    }
  }, [data]);

  return {
    formData,
    data,
    loading,
  };
};
