/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-no-bind */
import { LOGIN_PAGE_PATH } from 'helper/constants';
import { UserStateContext } from 'helper/userProvider';
import React, { FC, useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IMiddlewareProps } from './interface';

export const RoutesViewModel = () => {
  const { getUserData } = useContext(UserStateContext);

  const AuthMiddleware: FC<IMiddlewareProps> = ({ component: Component, requiredRole, ...rest }) => {
    const { checkAuth } = useContext(UserStateContext);
    const checkRole = getUserData() ? getUserData().roles.some(role => role.name === requiredRole) : null;

    return (
      <Route
        {...rest}
        render={props =>
          checkAuth() && checkRole ? <Component {...props} /> : <Redirect to={{ pathname: LOGIN_PAGE_PATH }} />
        }
      />
    );
  };

  const LoginMiddleware: FC<IMiddlewareProps> = ({ component: Component, ...rest }) => {
    const { checkAuth } = useContext(UserStateContext);

    return (
      <Route
        {...rest}
        // eslint-disable-next-line react/jsx-no-bind
        render={props => (!checkAuth() ? <Component {...props} /> : <Redirect to={{ pathname: '/' }} />)}
      />
    );
  };

  return {
    LoginMiddleware,
    AuthMiddleware,
  };
};
