import React, { createContext, useState, ReactNode } from 'react';
import decode from 'jwt-decode';
import { USER_ADMIN_ROLE, USER_PRACTITIONER_ROLE, USER_REVIEWER_ROLE } from './constants';
import { IDecodeProps } from './interface';

let authToken = localStorage.getItem('token');
const initial = {
  userState: { loggedIn: false },
  // eslint-disable-next-line no-unused-vars
  appSetLogin: (token: string) => {},
  appSetLogout: () => {},
  // eslint-disable-next-line no-unused-vars
  appSetAuthToken: (token: string) => {},
  appClearAuthToken: () => {},
  checkAuth: (): boolean => false,
  getUserData: (): IDecodeProps | null => null,
  practitioner: (): boolean => null,
  admin: (): boolean => null,
  reviewer: (): boolean => null,
  userName: (): string => null,
};

export const UserStateContext = createContext(initial);

function UserStateProvider({ children }: { children: ReactNode }) {
  // app state
  const [userState, setUserState] = useState({ loggedIn: false });

  const appSetLogin = (token: string) => {
    authToken = token;
    localStorage.setItem('token', authToken);
    setUserState({ ...userState, loggedIn: true });
  };

  const appSetLogout = () => {
    localStorage.removeItem('token');
    authToken = '';
    setUserState({ ...userState, loggedIn: false });
  };

  const appSetAuthToken = (token: string) => {
    authToken = token;
  };
  const appClearAuthToken = () => {
    authToken = '';
  };

  const checkAuth = (): boolean => {
    const token = authToken;

    if (!token) {
      return false;
    }

    try {
      const { exp } = decode<IDecodeProps>(token);

      if (exp < new Date().getTime() / 1000) {
        return false;
      }
    } catch (e) {
      appSetLogout();
      return false;
    }

    return true;
  };

  const getUserData = () => {
    try {
      const token = authToken;
      if (!token) {
        return null;
      }
      const tokenData = decode<IDecodeProps>(token);

      return tokenData;
    } catch (e) {
      return null;
    }
  };

  const userName = () => {
    const userData = getUserData();
    if (userData) {
      return `${userData.firstName} ${userData.lastName}`;
    }

    return '';
  };

  const practitioner = () => {
    const userRole = getUserData() ? getUserData().roles.some(role => role.name === `${USER_PRACTITIONER_ROLE}`) : null;
    if (userRole) {
      return userRole;
    }
    return false;
  };

  const reviewer = () => {
    const userRole = getUserData() ? getUserData().roles.some(role => role.name === `${USER_REVIEWER_ROLE}`) : null;
    if (userRole) {
      return userRole;
    }
    return false;
  };

  const admin = () => {
    const userRole = getUserData() ? getUserData().roles.some(role => role.name === `${USER_ADMIN_ROLE}`) : null;
    if (userRole) {
      return userRole;
    }
    return false;
  };

  return (
    <UserStateContext.Provider
      value={{
        userState,
        appSetLogin,
        appSetLogout,
        appSetAuthToken,
        appClearAuthToken,
        checkAuth,
        getUserData,
        practitioner,
        reviewer,
        admin,
        userName,
      }}>
      {children}
    </UserStateContext.Provider>
  );
}

export default UserStateProvider;
