/* eslint-disable max-len */

import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import { FC } from 'react';
import Header from './components/Header';
import SideBar from './components/SideBar';
import useStyles from './dashboardLayout.styles';
import { DashboardViewModel } from './DashboardLayout.ViewModel';

interface IDashboardProps {
  children: any;
}

const Dashboard: FC<IDashboardProps> = ({ children }) => {
  const classes = useStyles();
  const vm = DashboardViewModel();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header open={vm.open} handleDrawerOpen={vm.handleDrawerOpen} />
      <SideBar open={vm.open} handleDrawerClose={vm.handleDrawerClose} />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: vm.open,
        })}>
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  );
};

export default Dashboard;
