/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-no-bind */
import {
  ADMIN_CREATE_USER_PAGE_PATH,
  ADMIN_DOCUMENTS_PAGE_PATH,
  ADMIN_USERS_PAGE_PATH,
  LOGIN_PAGE_PATH,
  PRACTITIONER_CREATE_DOCUMENT_PAGE_PATH,
  PRACTITIONER_PAGE_PATH,
  PRACTITIONER_UPDATE_DOCUMENT_PAGE_PATH,
  PRACTITIONER_VIEW_DOCUMENT_PAGE_PATH,
  REVIEWER_PAGE_PATH,
  REVIEWER_REVIEW_PATH,
  REVIEWER_VIEW_PATH,
  USER_ADMIN_ROLE,
  USER_PRACTITIONER_ROLE,
  USER_REVIEWER_ROLE,
} from 'helper/constants';
import { UserStateContext } from 'helper/userProvider';
import CreateUser from 'pages/AdminCreateUserPage';
import AdminDocumentListPage from 'pages/AdminDocumentListPage/AdminDocumentListPage';
import AdminPage from 'pages/AdminPage/AdminPage';
import LoginPage from 'pages/LoginPage';
import PractitionerCreatePage from 'pages/PractitionerCreatePage/PractitionerCreatePage';
import PractitionerPage from 'pages/PractitionerPage';
import PractitionerUpdatePa from 'pages/PractitionerUpdatePa';
import PractitionerViewPa from 'pages/PractitionerViewPa/PractitionerViewPa';
import ReviewerPage from 'pages/ReviewerPage/ReviewerPage';
import ReviewerReviewPa from 'pages/ReviewerReviewPa';
import ReviewerViewPa from 'pages/ReviewerViewPa';
import { useContext } from 'react';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import { RoutesViewModel } from 'Routes/Routes.ViewModel';

const Routes = () => {
  const { admin, reviewer, practitioner } = useContext(UserStateContext);
  const vm = RoutesViewModel();

  return (
    <BrowserRouter>
      <Switch>
        <vm.LoginMiddleware exact path={LOGIN_PAGE_PATH} component={props => <LoginPage {...props} />} />
        <vm.AuthMiddleware
          exact
          path={PRACTITIONER_PAGE_PATH}
          component={props => <PractitionerPage {...props} />}
          requiredRole={USER_PRACTITIONER_ROLE}
        />
        <vm.AuthMiddleware
          exact
          path={PRACTITIONER_CREATE_DOCUMENT_PAGE_PATH}
          component={props => <PractitionerCreatePage {...props} />}
          requiredRole={USER_PRACTITIONER_ROLE}
        />
        <vm.AuthMiddleware
          exact
          path={PRACTITIONER_UPDATE_DOCUMENT_PAGE_PATH}
          component={props => <PractitionerUpdatePa {...props} />}
          requiredRole={USER_PRACTITIONER_ROLE}
        />
        <vm.AuthMiddleware
          exact
          path={PRACTITIONER_VIEW_DOCUMENT_PAGE_PATH}
          component={props => <PractitionerViewPa {...props} />}
          requiredRole={USER_PRACTITIONER_ROLE}
        />
        <vm.AuthMiddleware
          exact
          path={ADMIN_USERS_PAGE_PATH}
          component={props => <AdminPage {...props} />}
          requiredRole={USER_ADMIN_ROLE}
        />
        <vm.AuthMiddleware
          exact
          path={ADMIN_DOCUMENTS_PAGE_PATH}
          component={props => <AdminDocumentListPage {...props} />}
          requiredRole={USER_ADMIN_ROLE}
        />
        <vm.AuthMiddleware
          exact
          path={ADMIN_CREATE_USER_PAGE_PATH}
          component={props => <CreateUser {...props} />}
          requiredRole={USER_ADMIN_ROLE}
        />
        <vm.AuthMiddleware
          exact
          path={REVIEWER_PAGE_PATH}
          component={props => <ReviewerPage {...props} />}
          requiredRole={USER_REVIEWER_ROLE}
        />
        <vm.AuthMiddleware
          exact
          path={REVIEWER_REVIEW_PATH}
          component={props => <ReviewerReviewPa {...props} />}
          requiredRole={USER_REVIEWER_ROLE}
        />
        <vm.AuthMiddleware
          exact
          path={REVIEWER_VIEW_PATH}
          component={props => <ReviewerViewPa {...props} />}
          requiredRole={USER_REVIEWER_ROLE}
        />
        {(admin() && <Redirect to={ADMIN_USERS_PAGE_PATH} />) ||
          (reviewer() && <Redirect to={REVIEWER_PAGE_PATH} />) ||
          (practitioner() && <Redirect to={PRACTITIONER_PAGE_PATH} />) || <Redirect to={LOGIN_PAGE_PATH} />}
        {/* <Redirect to={ADMIN_USERS_PAGE_PATH} /> */}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
