import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: '#003767',
      color: 'white',
      borderRadius: '15px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },

    button: {
      marginTop: '30px',
      marginLeft: '20px',
      width: '200px',
      backgroundColor: '#003767',
      '&:hover': {
        backgroundColor: '#f78e1e',
      },
    },
  })
);

export default useStyles;
