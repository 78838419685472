import { Button, TextField, Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import useStyles from 'pages/AdminCreateUserPage/AdminCreateUserPage.styles';
import React from 'react';
import { CreateUserViewModel } from './CreateUserForm.ViewModel';

const CreateUserForm = () => {
  const classes = useStyles();
  const vm = CreateUserViewModel();

  return (
    <form className={classes.form} noValidate>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="firstName"
        label="First Name"
        name="firstName"
        autoComplete="firstName"
        autoFocus
        onChange={vm.handleInput}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="lastName"
        label="Last Name"
        autoComplete="lastName"
        id="lastName"
        onChange={vm.handleInput}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="email"
        label="Email Address"
        type="email"
        autoComplete="email"
        id="email"
        onChange={vm.handleInput}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        onChange={vm.handleInput}
      />
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="serviceLineId">Service Line ID</InputLabel>
        <Select
          className={classes.select}
          displayEmpty
          variant="outlined"
          margin="dense"
          value={vm.values.serviceLineId}
          label=">Service Line ID"
          onChange={vm.handleSelectChange}
          name="serviceLineId">
          {vm.serviceLines?.listServiceLines.map((serviceLine: any) => (
            <MenuItem key={serviceLine.id} value={serviceLine.id}>
              {serviceLine.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="reviewerId">Reivewer ID</InputLabel>
        <Select
          className={classes.select}
          displayEmpty
          variant="outlined"
          margin="dense"
          value={vm.values.reviewerId}
          onChange={vm.handleSelectChange}
          name="reviewerId">
          {vm.reviewers?.listUsers.users.map((users: any) => (
            <MenuItem key={users.id} value={users.id}>
              {`${users.firstName} ${users.lastName}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="rolesId">Roles ID</InputLabel>
        <Select
          className={classes.select}
          displayEmpty
          multiple
          variant="outlined"
          margin="dense"
          value={vm.values.rolesId}
          label="Roles ID"
          onChange={vm.handleSelectChange}
          name="rolesId">
          {vm.roles?.listRoles.map((role: any) => (
            <MenuItem key={role.id} value={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={vm.onSubmit}>
        Create User
      </Button>
    </form>
  );
};

export default CreateUserForm;
