import { Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import TableChartIcon from '@material-ui/icons/TableChart';
import useStyles from 'components/DashboardLayout/dashboardLayout.styles';
import { ADMIN_USERS_PAGE_PATH, PRACTITIONER_PAGE_PATH, REVIEWER_PAGE_PATH } from 'helper/constants';
import { UserStateContext } from 'helper/userProvider';
import { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import { ISideBarProps } from './interfaces';

const SideBar: FC<ISideBarProps> = ({ open, handleDrawerClose }) => {
  const { admin, reviewer, practitioner, userName } = useContext(UserStateContext);
  const { appSetLogout } = useContext(UserStateContext);
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}>
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItem key="User Name">
          <ListItemIcon>
            <AccountBoxIcon />
          </ListItemIcon>
          <ListItemText primary={userName()} />
        </ListItem>
        {practitioner() ? (
          <ListItem to={PRACTITIONER_PAGE_PATH} component={Link} button key="Practitioner Page">
            <ListItemIcon>
              <TableChartIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="Practitioner Page" className={classes.button} />
          </ListItem>
        ) : null}
        {reviewer() ? (
          <ListItem to={REVIEWER_PAGE_PATH} component={Link} button key="Reviewer Page">
            <ListItemIcon>
              <TableChartIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="Reviewer Page" className={classes.button} />
          </ListItem>
        ) : null}
        {admin() ? (
          <ListItem to={ADMIN_USERS_PAGE_PATH} component={Link} button key="Admin Page">
            <ListItemIcon color="primary">
              <TableChartIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="Admin Page" className={classes.button} />
          </ListItem>
        ) : null}

        <ListItem button key="Sign out" onClick={appSetLogout}>
          <ListItemIcon>
            <ExitToAppIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary="Sign Out" className={classes.button} />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default SideBar;
