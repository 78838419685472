import { useQuery } from '@apollo/client';
import { GET_RATING_LIST } from 'graphql/queries';
import { useEffect, useState } from 'react';

export const ReviewerGradesViewModel = () => {
  const [grades, setGrades] = useState([]);
  const { data } = useQuery(GET_RATING_LIST);

  useEffect(() => {
    if (data) {
      setGrades(data.listPaRatings);
    }
  }, [data]);

  return { grades };
};
