import DashboardLayout from 'components/DashboardLayout';
import FormPA from 'components/FormPA';
import FormPaStateProvider from 'components/FormPA/formPaProvider';
import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';

const PractitionerCreatePage: FC<RouteComponentProps> = () => (
  <div>
    <FormPaStateProvider>
      <DashboardLayout>
        <FormPA />
      </DashboardLayout>
    </FormPaStateProvider>
  </div>
);

export default PractitionerCreatePage;
