/* eslint-disable no-unused-vars */
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_PA_DOCUMENT, EDIT_PA_DOCUMENT, REVIEW_PA_DOCUMENT } from 'graphql/mutations';
import { GET_ALL_REVIEWER_USERS, GET_COMPONENT_LIST } from 'graphql/queries';
import { UserStateContext } from 'helper/userProvider';
import React, { ChangeEvent, createContext, FC, MouseEvent, ReactNode, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

const initial = {
  handleInput: (e: ChangeEvent<HTMLInputElement>) => {},
  values: {} as { [key: string]: any },
  reviewerList: [],
  handleReviewerChange: (e: ChangeEvent<{ name?: string; value: unknown }>) => {},
  components: [],
  openSubmit: false,
  openDraft: false,
  handleEditDetailInput: (
    componentId: any,
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<{
          name?: string;
          value: unknown;
        }>,
    field?: string
  ) => {},
  handleDetailInput: (
    componentId: any,
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<{
          name?: string;
          value: unknown;
        }>,
    field?: string
  ) => {},
  onSubmit: (e: MouseEvent<HTMLButtonElement>, statusName: string) => {},
  handleCloseSubmit: () => {},
  handleCloseDraft: () => {},
  handleOpenSubmit: () => {},
  handleOpenDraft: () => {},
  getUserDataReviewer: () => {},
  onSubmitReview: (e: MouseEvent<HTMLButtonElement>, statusName: string) => {},
  viewPage: true,
  editPage: true,
  reviewer: true,
  disableInfo: true,
  disableDetails: true,
};

export const FormPaStateContext = createContext(initial);

const FormPaStateProvider: FC<{
  children: ReactNode;
  formDetails?: any;
  viewPage?: boolean;
  editPage?: boolean;
  reviewer?: boolean;
  disableInfo?: boolean;
  disableDetails?: boolean;
}> = ({ children, formDetails, viewPage, editPage, reviewer, disableInfo, disableDetails }) => {
  const { getUserData } = useContext(UserStateContext);
  const [components, setComponents] = useState([]);
  const { data } = useQuery(GET_COMPONENT_LIST);
  const [createPaDocument] = useMutation(CREATE_PA_DOCUMENT);
  const [editPaDocument] = useMutation(EDIT_PA_DOCUMENT);
  const [reviewPaDocument] = useMutation(REVIEW_PA_DOCUMENT);
  const [openDraft, setOpenDraft] = useState(false);
  const [openSubmit, setOpenSubmit] = useState(false);
  const [reviewerList, setReviewerList] = useState<[]>([]);

  const { data: reviewers } = useQuery(GET_ALL_REVIEWER_USERS, {
    variables: {
      listUsersInput: {
        filter: { role: 2 },
        pagination: {
          limit: 100,
          page: 1,
        },
      },
    },
  });

  const getUserDataReviewer = () => {
    const userDataReviewer = getUserData();
    if (userDataReviewer) {
      return userDataReviewer.reviewer.id;
    }

    return '';
  };

  // eslint-disable-next-line no-console
  console.log('test', getUserDataReviewer());

  const getUserDataID = () => {
    const userData = getUserData();
    if (userData) {
      return userData.id;
    }

    return '';
  };

  const [values, setValues] = useState<any>(
    formDetails || {
      projectName: '',
      role: '',
      year: '',
      monthFromTo: '',
      hoursSpent: '',
      results: {
        componentId: '',
        practitionerComment: '',
      },
      relationships: {
        componentId: '',
        practitionerComment: '',
      },
      professionalEffectiveness: {
        componentId: '',
        practitionerComment: '',
      },
      leadingOthers: {
        componentId: '',
        practitionerComment: '',
      },
      business: {
        componentId: '',
        practitionerComment: '',
      },
      financialManagement: {
        componentId: '',
        practitionerComment: '',
      },
      practitionerId: getUserDataID(),
      reviewerId: getUserDataReviewer(),
    }
  );

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    switch (e.target.name) {
      case 'hoursSpent':
        setValues({
          ...values,
          hoursSpent: Number(e.target.value),
        });

        break;

      case 'year':
        setValues({
          ...values,
          year: Number(e.target.value),
        });

        break;

      default:
        setValues({
          ...values,
          [e.target.name]: e.target.value,
        });
    }
  };

  const handleReviewerChange = (e: ChangeEvent<{ name?: string; value: unknown }>) => {
    setValues((prev: any) => ({
      ...prev,
      reviewerId: e.target.value,
    }));
  };

  const handleEditDetailInput = (
    documentComponentId: any,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field?: string
  ) => {
    if (field) {
      setValues((prev: any) => ({
        ...prev,
        documentComponents: values.documentComponents.map((dc: { id: any }) => {
          if (documentComponentId === dc.id) {
            return {
              ...dc,
              [field]: e.target.value,
            };
          }

          return dc;
        }),
      }));
    }
  };

  const handleDetailInput = (componentId: any, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (e.target.name) {
      case 'Results':
        setValues({
          ...values,
          results: { componentId, practitionerComment: e.target.value },
        });

        break;
      case 'Relationships':
        setValues({
          ...values,
          relationships: { componentId, practitionerComment: e.target.value },
        });

        break;
      case 'Professional Effectiveness':
        setValues({
          ...values,
          professionalEffectiveness: { componentId, practitionerComment: e.target.value },
        });

        break;
      case 'Leading Others':
        setValues({
          ...values,
          leadingOthers: { componentId, practitionerComment: e.target.value },
        });

        break;
      case 'Business':
        setValues({
          ...values,
          business: { componentId, practitionerComment: e.target.value },
        });

        break;

      case 'Financial Management':
        setValues({
          ...values,
          financialManagement: { componentId, practitionerComment: e.target.value },
        });

        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (data) {
      setComponents(data.listPaComponents);
    }
    if (reviewers) {
      setReviewerList(reviewers.listUsers.users);
    }
  }, [data, reviewers]);

  const handleOpenDraft = () => {
    setOpenDraft(true);
  };

  const handleCloseDraft = () => {
    setOpenDraft(false);
  };

  const handleOpenSubmit = () => {
    setOpenSubmit(true);
  };

  const handleCloseSubmit = () => {
    setOpenSubmit(false);
  };

  // eslint-disable-next-line consistent-return
  const onSubmit = async (e: MouseEvent<HTMLButtonElement>, statusName: string) => {
    try {
      e.preventDefault();

      if (formDetails) {
        return await editPaDocument({
          variables: {
            editPaDocumentInput: {
              ...values,
              documentComponents: values.documentComponents.map(dc => ({
                id: dc.id,
                practitionerComment: dc.practitionerComment,
              })),
              statusName,
            },
          },
        });
      }

      await createPaDocument({ variables: { createPaDocumentInput: { ...values, statusName } } });
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert(err);
    }
  };

  const onSubmitReview = async (e: MouseEvent<HTMLButtonElement>, statusName: string) => {
    try {
      e.preventDefault();
      await reviewPaDocument({
        variables: {
          updatePaDocumentComponentInput: {
            statusName,
            id: values.id,
            documentComponents: values.documentComponents.map(dc => ({
              id: dc.id,
              reviewerComment: dc.reviewerComment,
              paRatingId: dc.paRatingId,
            })),
          },
        },
      });
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert(err);
    }
  };
  return (
    <FormPaStateContext.Provider
      value={{
        components,
        openSubmit,
        openDraft,
        handleInput,
        handleDetailInput,
        handleEditDetailInput,
        onSubmit,
        handleCloseSubmit,
        handleCloseDraft,
        handleOpenSubmit,
        handleOpenDraft,
        values,
        reviewerList,
        handleReviewerChange,
        getUserDataReviewer,
        onSubmitReview,
        viewPage,
        editPage,
        reviewer,
        disableInfo,
        disableDetails,
      }}>
      {children}
    </FormPaStateContext.Provider>
  );
};

export default FormPaStateProvider;
