import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { FormPaStateContext } from 'components/FormPA/formPaProvider';
import React, { FC, useContext } from 'react';
import { IReviewerGradesProps } from './interface';
import { ReviewerGradesViewModel } from './ReviewerGrades.ViewModel';

const ReviewerGrades: FC<IReviewerGradesProps> = ({ index }) => {
  const { handleEditDetailInput, values, editPage, viewPage, disableDetails } = useContext(FormPaStateContext);
  const vm = ReviewerGradesViewModel();

  return (
    <div>
      <FormControl variant="outlined" disabled={editPage || viewPage || disableDetails} style={{ width: '250px' }}>
        <InputLabel id="demo-simple-select-outlined-label">Grades</InputLabel>
        <Select
          // eslint-disable-next-line react/jsx-no-bind
          onChange={e => handleEditDetailInput(values?.documentComponents[index].id, e, 'paRatingId')}
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={values?.documentComponents[index].paRatingId}
          defaultValue=""
          label={values?.documentComponents[index].paRatingId}>
          {(vm.grades || []).map((rating: { id: string; grade: string }) => (
            <MenuItem key={rating.id} value={rating.id}>
              {rating.grade}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default ReviewerGrades;
