import { useQuery } from '@apollo/client';
import { Button } from '@material-ui/core';
import { GET_REVIEWER_DOCUMENT_LIST } from 'graphql/queries';
// eslint-disable-next-line import/named
import { UserStateContext } from 'helper/userProvider';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IListProps } from './interface';

export const ReviewerPageViewModel = () => {
  const { getUserData } = useContext(UserStateContext);
  const filter = false;
  const [documents, setDocuments] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState<number>(0);
  const [limit, setLimit] = useState(5);
  const [status, setStatus] = useState('');

  const getUserDataID = useCallback(() => {
    const userData = getUserData();
    if (userData) {
      return userData.id;
    }

    return '';
  }, [getUserData]);

  const { data, fetchMore } = useQuery(GET_REVIEWER_DOCUMENT_LIST, {
    variables: {
      listPaDocumentsForReviewerInput: {
        reviewerId: getUserDataID(),
        ...(status ? { filterByStatus: status } : null),
        pagination: {
          limit,
          page: page + 1,
        },
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      setDocuments(
        data.listPaDocumentsForReviewer.paDocuments.map((list: IListProps) => ({
          id: list.id,
          projectName: list.projectName,
          createdAt: list.createdAt,
          monthFromTo: list.monthFromTo,
          hoursSpent: list.hoursSpent,
          status: list.status.id,
          practitioner: `${list.practitioner.firstName} ${list.practitioner.lastName}`,
        }))
      );
      setCount(data.listPaDocumentsForReviewer.count);
    }
  }, [data]);

  const fetchData = useCallback(() => {
    fetchMore({
      variables: {
        listPaDocumentsForReviewerInput: {
          reviewerId: getUserDataID(),
          ...(status ? { filterByStatus: status } : null),
          pagination: {
            limit,
            page: page + 1,
          },
        },
      },
    });
  }, [fetchMore, getUserDataID, status, limit, page]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // const handleFilterChange = () => {
  //   setFilter(!filter);
  // };

  const columns = [
    {
      title: 'ID',
      field: 'id',
      sorting: false,
      filtering: false,
    },
    {
      title: 'Project name',
      field: 'projectName',
      sorting: false,
      filtering: false,
    },
    {
      title: 'Created at',
      field: 'createdAt',
      sorting: false,
      filtering: false,
    },
    {
      title: 'Month from to',
      field: 'monthFromTo',
      sorting: false,
      filtering: false,
    },
    {
      title: 'Hours spent',
      field: 'hoursSpent',
      sorting: false,
      filtering: false,
    },
    {
      title: 'Practitioner',
      field: 'practitioner',
      sorting: false,
      filtering: false,
    },
    {
      title: 'Status',
      field: 'status',
      lookup: { 2: 'Needs Review', 3: 'Already Reviewed' },
      sorting: false,
    },
    {
      title: 'Action',
      field: 'id',
      align: 'center',
      sorting: false,
      filtering: false,
      // eslint-disable-next-line no-confusing-arrow
      render: (row: { status: number; id: string }) =>
        // eslint-disable-next-line react/destructuring-assignment
        row.status === 3 ? (
          // eslint-disable-next-line react/destructuring-assignment
          <Button to={`/reviewer/pa/view/${row.id}`} component={Link} color="primary" style={{ fontWeight: 'bold' }}>
            View
          </Button>
        ) : (
          // eslint-disable-next-line react/destructuring-assignment
          <Button to={`/reviewer/pa/update/${row.id}`} component={Link} style={{ fontWeight: 'bold' }} color="primary">
            Edit
          </Button>
        ),
    },
  ];

  const title = 'Reviewer documents';

  return {
    count,
    page,
    limit,
    filter,
    documents,
    columns,
    title,
    // handleFilterChange,
    setPage,
    setLimit,
    setStatus,
  };
};
