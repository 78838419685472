import { useMutation, useQuery } from '@apollo/client';
import { CREATE_USER } from 'graphql/mutations';
import { GET_REVIEWERS, GET_ROLES, GET_SERVICE_LINE } from 'graphql/queries';
import { ChangeEvent, MouseEvent, useState } from 'react';
import { useHistory } from 'react-router';
import { IValuesProps } from './interface';

export const CreateUserViewModel = () => {
  const history = useHistory();
  const [CreateNewUserMutation] = useMutation(CREATE_USER);
  const [values, setValues] = useState<IValuesProps>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    serviceLineId: '',
    rolesId: [] as any,
  });

  const { data: roles } = useQuery(GET_ROLES);
  const { data: serviceLines } = useQuery(GET_SERVICE_LINE);
  const { data: reviewers } = useQuery(GET_REVIEWERS);

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (e: any) => {
    switch (e.target.name) {
      case 'serviceLineId':
        setValues({
          ...values,
          serviceLineId: e.target.value,
        });

        break;

      case 'reviewerId':
        setValues({
          ...values,
          reviewerId: e.target.value,
        });

        break;

      case 'rolesId':
        setValues({
          ...values,
          rolesId: e.target.value,
        });

        break;

      default:
        setValues({ ...values, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
    try {
      e.preventDefault();
      const response = await CreateNewUserMutation({ variables: { createNewUserInput: values } });

      // eslint-disable-next-line no-console
      console.log(response.data.createdNewUser);
      // console.log(values);
      history.push('/admin');
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert(err);
    }
  };
  return {
    values,
    setValues,
    handleInput,
    handleSelectChange,
    onSubmit,
    roles,
    serviceLines,
    reviewers,
  };
};
