import { Button } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import React, { FC } from 'react';
import { IModalProps } from './interfaces';
import useStyles from './modal.styles';

const TransitionsModal: FC<IModalProps> = ({ submit, open, title, desc, value, action, handleClose, handleOpen }) => {
  const classes = useStyles();

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        // eslint-disable-next-line react/jsx-no-bind
        onClick={e => {
          submit(e, action);
          handleOpen();
        }}
        value={value}>
        {title}
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">{title}</h2>
            <p id="transition-modal-description">{desc}</p>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default TransitionsModal;
