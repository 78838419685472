import { gql } from '@apollo/client';

export const GET_COMPONENT_LIST = gql`
  query Query {
    listPaComponents {
      name
      desc
      id
    }
  }
`;

export const GET_DOCUMENT_LIST = gql`
  query Query($listPaDocumentsInput: ListPaDocumentsInput!) {
    listPaDocuments(input: $listPaDocumentsInput) {
      count
      paDocuments {
        projectName
        id
        year
        createdAt
        status {
          name
          id
        }
        reviewer {
          firstName
          lastName
        }
        hoursSpent
        monthFromTo
      }
    }
  }
`;

export const GET_USERS = gql`
  query Query($listUsersInput: ListUsersInput!) {
    listUsers(input: $listUsersInput) {
      count
      users {
        id
        firstName
        lastName
        email
        createdAt
        serviceLine {
          name
        }
        roles {
          name
        }
        reviewer {
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_PA_DOCUMENT = gql`
  query Query($getPaDocumentInput: GetPaDocumentInput!) {
    getPaDocument(input: $getPaDocumentInput) {
      id
      projectName
      role
      year
      monthFromTo
      hoursSpent
      reviewerId
      documentComponents {
        id
        practitionerComment
        paRatingId
        reviewerComment
        component {
          id
          name
        }
      }
    }
  }
`;
export const GET_ALL_REVIEWER_USERS = gql`
  query Query($listUsersInput: ListUsersInput!) {
    listUsers(input: $listUsersInput) {
      users {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_REVIEWER_DOCUMENT_LIST = gql`
  query Query($listPaDocumentsForReviewerInput: ListPaDocsForReviewerInput!) {
    listPaDocumentsForReviewer(input: $listPaDocumentsForReviewerInput) {
      paDocuments {
        id
        projectName
        hoursSpent
        monthFromTo
        createdAt
        status {
          name
          id
        }
        practitioner {
          firstName
          lastName
        }
      }
      count
    }
  }
`;

export const GET_RATING_LIST = gql`
  query Query {
    listPaRatings {
      id
      grade
      desc
    }
  }
`;

export const GET_PA_DOCUMENT_FOR_REVIEWER = gql`
  query Query($getPaDocumentInput: GetPaDocumentInput!) {
    getPaDocument(input: $getPaDocumentInput) {
      id
      projectName
      role
      reviewerId
      year
      monthFromTo
      hoursSpent
      documentComponents {
        id
        practitionerComment
        component {
          id
          name
        }
        paRatingId
        paRating {
          id
        }
        reviewerComment
      }
    }
  }
`;

export const GET_SERVICE_LINE = gql`
  query Query {
    listServiceLines {
      id
      name
    }
  }
`;

export const GET_ROLES = gql`
  query Query {
    listRoles {
      id
      name
      desc
    }
  }
`;

export const GET_REVIEWERS = gql`
  query Query {
    listUsers(input: { pagination: { limit: 100, page: 1 }, filter: { role: 2 } }) {
      users {
        firstName
        lastName
        id
      }
    }
  }
`;

export const GET_ADMIN_DOCUMENT_LIST = gql`
  query Query($listPaDocumentsForAdminInput: ListPaDocumentsForAdminInput!) {
    listPaDocumentsForAdmin(input: $listPaDocumentsForAdminInput) {
      paDocuments {
        projectName
        practitioner {
          firstName
          lastName
        }
        reviewer {
          firstName
          lastName
        }
        createdAt
        hoursSpent
        monthFromTo
        id
        status {
          name
          id
        }
      }
      count
    }
  }
`;
