import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #003767',
      borderRadius: '15px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },

    button: {
      backgroundColor: '#003767',
      color: 'white',
      marginBottom: '10px',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: '#f78e1e',
      },
    },
  })
);

export default useStyles;
