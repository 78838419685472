import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  optionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1.3rem',
  },

  select: {
    width: 100,
    marginLeft: '10px',
  },

  button: {
    backgroundColor: '#003767',
    color: 'white',
    marginBottom: '10px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#f78e1e',
    },
  },
}));

export default useStyles;
