import { useQuery } from '@apollo/client';
import { Button } from '@material-ui/core';
import { GET_DOCUMENT_LIST } from 'graphql/queries';
// eslint-disable-next-line import/named
import { UserStateContext } from 'helper/userProvider';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IListProps } from './interface';

export const PractitionerPageViewModel = () => {
  const { getUserData } = useContext(UserStateContext);
  const [filter, setFilter] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState<number>(0);
  const [limit, setLimit] = useState(5);
  const [status, setStatus] = useState('');
  const [sort, setSort] = useState('');

  const getUserDataID = useCallback(() => {
    const userData = getUserData();
    if (userData) {
      return userData.id;
    }

    return '';
  }, [getUserData]);

  const { data, fetchMore } = useQuery(GET_DOCUMENT_LIST, {
    variables: {
      listPaDocumentsInput: {
        practitionerId: getUserDataID(),
        ...(sort ? { sortByDate: sort } : null),
        ...(status ? { filterByStatus: status } : null),
        pagination: {
          limit,
          page: page + 1,
        },
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      setDocuments(
        data.listPaDocuments.paDocuments.map((list: IListProps) => ({
          id: list.id,
          projectName: list.projectName,
          createdAt: list.createdAt,
          monthFromTo: list.monthFromTo,
          hoursSpent: list.hoursSpent,
          status: list.status.name,
          reviewer: `${list.reviewer.firstName} ${list.reviewer.lastName}`,
        }))
      );
      setCount(data.listPaDocuments.count);
    }
  }, [data]);

  const fetchData = useCallback(() => {
    fetchMore({
      variables: {
        listPaDocumentsInput: {
          practitionerId: getUserDataID(),
          ...(sort ? { sortByDate: sort } : null),
          ...(status ? { filterByStatus: status } : null),
          pagination: {
            limit,
            page: page + 1,
          },
        },
      },
    });
  }, [fetchMore, getUserDataID, sort, status, limit, page]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleFilterChange = () => {
    setFilter(!filter);
  };

  const columns = [
    {
      title: 'ID',
      field: 'id',
      sorting: false,
    },
    {
      title: 'Project name',
      field: 'projectName',
      sorting: false,
    },
    {
      title: 'Created at',
      field: 'createdAt',
    },
    {
      title: 'Month from to',
      field: 'monthFromTo',
      sorting: false,
    },
    {
      title: 'Hours spent',
      field: 'hoursSpent',
      sorting: false,
    },
    {
      title: 'Reviewer',
      field: 'reviewer',
      sorting: false,
    },
    {
      title: 'Status',
      field: 'status',
      lookup: { Draft: 'Draft', Submitted: 'Submitted', Reviewed: 'Reviewed' },
    },
    {
      title: 'Action',
      field: 'id',
      align: 'center',
      sorting: false,
      // eslint-disable-next-line no-confusing-arrow
      render: (row: { status: string; id: string }) =>
        // eslint-disable-next-line react/destructuring-assignment
        row.status === 'Draft' ? (
          <Button
            // eslint-disable-next-line react/destructuring-assignment
            to={`/practitioner/pa/update/${row.id}`}
            component={Link}
            color="primary"
            style={{ fontWeight: 'bold' }}>
            {/* eslint-disable-next-line react/destructuring-assignment */}
            Edit
          </Button>
        ) : (
          <Button
            // eslint-disable-next-line react/destructuring-assignment
            to={`/practitioner/pa/view/${row.id}`}
            component={Link}
            style={{ fontWeight: 'bold' }}
            color="primary">
            View
          </Button>
        ),
    },
  ];

  // eslint-disable-next-line no-console
  console.log(documents);

  const title = 'Practitioner documents';

  return {
    setSort,
    count,
    page,
    limit,
    filter,
    documents,
    columns,
    title,
    handleFilterChange,
    setPage,
    setLimit,
    setStatus,
  };
};
